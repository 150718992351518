<template></template>

<script>
export default {
  created() {
    window.location =
      "https://www.eventbrite.com.mx/e/flisol-cancun-2022-tickets-318258268047";
  },
};
</script>

<style></style>
